import React from 'react';
import ssWhiteLogo from '../img/ss-white-logo.png'; 
import fbLogo from '../img/footer-icon/facebook.png';
import igLogo from '../img/footer-icon/instagram.png';
import { Link } from 'react-router-dom';

const Footer = () => {

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="w-full h-auto bg-black flex flex-col">
      <div className="footer-cotainer text-white py-6 px-[1rem] md:px-[2rem] lg:px-[7rem] flex flex-wrap md:flex-nowrap gap-[2rem] justify-center md:justify-between items-center">
      <div className="flex  md:w-min flex-col w-full gap-[1rem] items-center md:items-start">
        <img src={ssWhiteLogo} alt="Snipers Security White Logo" className="w-[13rem] h-auto" />
        <span className="footer-description w-[20rem] lg:w-[22rem] text-center md:text-left">
        Snipers Security Agency Inc. provides <span className="text-primary">top-notch, premium, and unsurpassed</span> security services for all of your business needs.
        </span>
        <span className="social-container flex gap-3">
        <a href="https://www.facebook.com/profile.php?id=100063961063921" target="_blank" rel="noopener noreferrer">
        <img src={fbLogo} alt="social media logo" className="w-[2rem] h-auto" />
        </a>
        <a href="https://www.instagram.com/sniperssecurityagency/" target="_blank" rel="noopener noreferrer">
          <img src={igLogo} alt="social media logo" className="w-[2rem] h-auto" />
        </a>
        </span>
      </div>
      <div className="text-left flex md:w-[25rem] justify-around justify-center gap-4">
        <span className="block">
            <span className="footer-links-title font-bold mb-4 flex flex-col">
                Our Links
            </span >
            <span className="flex flex-col">
              <Link to="/about-us" onClick={scrollToTop}>
                <span className='mb-2'>About Us</span>
              </Link>
              <Link to="/contact-us" onClick={scrollToTop}>
                <span className='mb-2'>Contact Us</span>
              </Link>
              <Link to="/security-services" onClick={scrollToTop}>
                <span className='mb-3'>Security Services</span>
              </Link>
              <Link to="/business-services" onClick={scrollToTop}>
                <span className='mb-2'>Business Services</span>
              </Link>
            </span>
        </span>
        <span className="block">
            <span className="footer-contact-title font-bold mb-4 flex flex-col">
                Stay Updated
            </span>
            <span className="flex flex-col ">
                <span className='mb-2'>(+63) 917 114 4503</span>
                <span className='mb-2'>(+63) 928 392 8397</span>
                <span className='mb-2'>(02) 7358 2445</span>
            </span>
        </span>
      </div>
      </div>
      <div className="design-by flex justify-between w-full border-t-[1px] border-white py-[20px] px-8 w-[90%] mx-auto">
        <span className="text-base text-white"> 
        Copyright © 2024 Snipers Co. | All Rights Reserved | All Rights Reserved | Designed By: Divine Software Systems
        </span>
        <Link to="/privacy-policy" onClick={scrollToTop}>
        <span className="text-base text-white"> 
        Privacy Policy
        </span>
        </Link>
      </div>
    </div>
  );
};

export default Footer;
