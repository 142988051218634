// src/App.js
import './App.css';
import React, { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4'; // Import Google Analytics
import Modal from 'react-modal'; // Import React Modal for the cookie popup
import { Helmet } from 'react-helmet'; // Import Helmet for SEO and meta tags
import Navbar from './components/Navbar';
import Topbar from './components/Topbar';
import Footer from './components/Footer';
import PrivacyPolicy from './components/PrivacyPolicy';

const Home = lazy(() => import('./components/Home'));
const BusinessServices = lazy(() => import('./components/BusinessServices'));
const SecurityServices = lazy(() => import('./components/SecurityServices'));
const AboutUs = lazy(() => import('./components/AboutUs'));
const ContactUsPage = lazy(() => import('./components/ContactUsPage'));

// Initialize Google Analytics
const initGA = () => {
  const trackingId = process.env.REACT_APP_GA_TRACKING_ID;
  if (trackingId) {
    ReactGA.initialize(trackingId);
  }
};

// Component to handle page tracking with useLocation
const ScrollToTopAndTrack = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top
    ReactGA.send({ hitType: "pageview", page: pathname }); // Track pageview
  }, [pathname]);

  return null;
};

Modal.setAppElement('#root'); // To avoid accessibility issues with React Modal

function App() {
  // Show the modal unless cookies have been accepted
  const [isCookieModalOpen, setIsCookieModalOpen] = React.useState(() => {
    return localStorage.getItem("cookieConsent") !== "true";
  });

  // Initialize Google Analytics on app load if cookies have been accepted
  useEffect(() => {
    if (localStorage.getItem("cookieConsent") === "true") {
      initGA();
    }
  }, []);

  // Handle Accept Cookies
  const handleAcceptCookies = () => {
    localStorage.setItem("cookieConsent", "true");
    setIsCookieModalOpen(false); // Close the modal and never show it again
    initGA(); // Initialize Google Analytics after accepting cookies
  };

  // Handle Decline Cookies (hide temporarily)
  const handleDeclineCookies = () => {
    setIsCookieModalOpen(false); // Hide modal for this session only
  };

  return (
    <Router>
      <ScrollToTopAndTrack /> {/* This is now inside the Router */}
      <div className="App">
        {/* Helmet for default SEO metadata */}
        <Helmet>
          <title>Snipers Security Agency Inc. | Trusted Security Solutions</title>
          <meta 
            name="description" 
            content="A premier security agency providing top-notch, premium, and unsurpassed security services to ensure the safety of your business and assets." 
          />
          <meta 
            name="keywords" 
            content="security services, business protection, security agency, Snipers Security, corporate security" 
          />
          <meta property="og:title" content="Snipers Security Agency Inc. - Trusted Security Solutions" />
          <meta property="og:description" content="Your trusted partner in providing comprehensive security solutions." />
          <meta property="og:url" content="https://www.sniperssecurity.com.ph/home" />
          <meta property="og:type" content="website" />
          <meta property="og:image" content="https://www.sniperssecurity.com.ph/path_to_your_image.png" />
          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:title" content="Snipers Security Agency Inc. - Trusted Security Solutions" />
          <meta property="twitter:description" content="A premier security agency providing top-notch, premium, and unsurpassed security services." />
          <meta property="twitter:image" content="https://www.sniperssecurity.com.ph/path_to_your_image.png" />
        </Helmet>

        <Topbar />
        <Navbar />

        <Suspense fallback={
          <div className='loader-container w-full bg-[#272727] h-screen flex justify-center items-center'>
            <div className='loader'></div>
          </div>
        }>
          <Routes>
            <Route path="/" element={<Navigate to="/home" />} />
            <Route path="/home" element={<Home />} />
            <Route path="/business-services" element={<BusinessServices />} />
            <Route path="/security-services" element={<SecurityServices />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/contact-us" element={<ContactUsPage />} />
          </Routes>
        </Suspense>

        <Footer />

        {/* Cookie Consent Modal */}
        {isCookieModalOpen && (
          <Modal
            isOpen={isCookieModalOpen}
            onRequestClose={handleAcceptCookies}
            contentLabel="Cookie Consent Modal"
            className="cookie-modal"
            overlayClassName="cookie-modal-overlay"
            shouldFocusAfterRender={false} // Prevents auto-focus on the modal
          >
            <div className="cookie-modal-content">
              <h2>Cookie Consent</h2>
              <p>
                We use cookies to enhance your browsing experience, analyze traffic, and improve our services. By using this website, you consent to our use of cookies.
              </p>
              <p className='learn-more-cookie'>
                Learn more in our <a href="/privacy-policy" style={{ color: "#ff0000", textDecoration: "underline" }}>Privacy Policy</a>.
              </p>
              <div className="cookie-modal-buttons">
                <button onClick={handleAcceptCookies} className="accept-button">
                  Accept
                </button>
                <button onClick={handleDeclineCookies} className="manage-button">
                  Decline
                </button>
              </div>
            </div>
          </Modal>
        )}
      </div>
    </Router>
  );
}

export default App;
