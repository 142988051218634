import { useInView } from 'react-intersection-observer';

const useScrollAnimation = (options = {}) => {
  const { ref, inView } = useInView({
    threshold: 0, // Trigger when the element is slightly in view
    rootMargin: '-100px 0px', // Adjust this to start animation before it's in full view
    triggerOnce: true, // Trigger animation only once
  });

  return { ref, inView };
};

export default useScrollAnimation;
