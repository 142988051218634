import React from 'react';
import addressIcon from '../img/topbar-icon/address-icon.png';
import emailIcon from '../img/topbar-icon/email-icon.png';
import phoneIcon from '../img/topbar-icon/phone-icon.png';
import businessHoursIcon from '../img/topbar-icon/business-hours-icon.png';


const Topbar = () => {
  return (
    <div id="top-section" className="w-full h-fit bg-black py-4">
      <div className="text-container text-white text-sm flex gap-[1rem] flex-wrap text-left justify-start max-w-[80%] md:max-w-full mx-auto md:justify-evenly px-4">
        <div className="flex items-center space-x-2">
          <img src={addressIcon} alt="Address Icon" className="w-8 h-auto" />
          <span>G/F Unit C, Nisus Building Sta. Cruz Manila</span>
        </div>
        <div className="flex items-center space-x-2">
          <img src={emailIcon} alt="Email Icon" className="w-8 h-auto" />
          <span>snipersecurity@gmail.com</span>
        </div>
        <div className="flex items-center space-x-2">
          <img src={phoneIcon} alt="Phone Icon" className="w-8 h-auto" />
          <span>
            (+63) 917 114 4503,
            (+63) 928 392 8397,
            (02) 7358 2445
          </span>
        </div>
        <div className="flex items-center space-x-2">
          <img src={businessHoursIcon} alt="Business Hours Icon" className="w-8 h-auto" />
          <span>Mon - Fri 8:30pm to 6:30pm<br />Sat, Sun, Holidays 9am to 4pm</span>
        </div>
      </div>
    </div>
  );
};

export default Topbar;
