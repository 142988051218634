import React from 'react';
import heroBg from '../img/hero-bg-min.webp';
import lightBg from '../img/light-bg.webp'; 
import useScrollAnimation from '../hooks/useScrollAnimation';

const PrivacyPolicy = () => {
  const { ref: privacyPolicyRef, inView: privacyPolicyInView } = useScrollAnimation();
  return (
    <div>
      <div ref={privacyPolicyRef} className={`transition-opacity duration-500 ${privacyPolicyInView ? 'opacity-100' : 'opacity-0'}`}>
      <div
  className={`page-hero h-[300px] max-h-[300px] bg-cover bg-center flex justify-center items-center bg-[#464646] bg-blend-overlay`}
  style={{
    backgroundImage: `url(${heroBg})`,
    height: window.innerWidth < 468 ? '350px' : '300px',
    maxHeight: window.innerWidth < 468 ? '350px' : '300px',
  }}
  id="privacy-policy-section"
>
  <div className="page-title-container text-white mt-[50px] md:mt-0">
    <div className="title-container text-4xl md:text-5xl font-bold mt-10 mb-4 tracking-wide ">Privacy Policy</div>
    <span className="sub-title font-[500] text-xl">Home <span className="text-primary">→</span> Privacy Policy</span>
  </div>
</div>

    

{/* Privacy Policy contents */}
    <div className="content-container ">
        <div className="privacy-policy-content">
          <div className="h-max bg-cover bg-center px-[1rem] py-12 sm:py-20" style={{ backgroundImage: `url(${lightBg})` }}>
            <div className="flex flex-wrap flex-col-reverse max-w-[90rem] gap-[2rem] mx-auto flex-col md:flex-row justify-center">
            <div className="container mx-auto px-4 text-black text-left">
                <h1 className="text-3xl font-bold mb-4 text-primary">Privacy Policy</h1>

                <p className="text-lg mb-6">
                    Snipers Security is committed to protecting your privacy. This Privacy Policy outlines how we collect, use, disclose, and safeguard your personal information when you visit our website, www.sniperssecurity.com, or interact with our security services.
                </p>

                <h2 className="text-2xl font-semibold mb-4 text-primary">Information We Collect</h2>
                <ul className="list-disc list-inside mb-6">
                    <li>
                    <strong className="text-black">Information You Provide:</strong> This includes information you voluntarily provide, such as your name, contact details, email address, and any other information you choose to share.
                    </li>
                    <li>
                    <strong className="text-black">Automatically Collected Information:</strong> We may automatically collect certain information about your device and your interactions with our website, such as your IP address, browser type, operating system, referring URLs, and clickstream data.
                    </li>
                </ul>

                <h2 className="text-2xl font-semibold mb-4 text-primary">How We Use Your Information</h2>
                <ul className="list-disc list-inside mb-6">
                    <li>
                    <strong className="text-black">To provide our security services:</strong> We use your information to deliver the security services you have requested.
                    </li>
                    <li>
                    <strong className="text-black">To communicate with you:</strong> We may use your contact information to respond to your inquiries, provide updates, or send you relevant information about our services.
                    </li>
                    <li>
                    <strong className="text-black">To improve our services:</strong> We analyze your information to understand your needs and preferences, and to improve our website and services.
                    </li>
                    <li>
                    <strong className="text-black">To comply with legal requirements:</strong> We may use your information to comply with applicable laws and regulations.
                    </li>
                </ul>

                <h2 className="text-2xl font-semibold mb-4 text-primary">Disclosure of Your Information</h2>
                <p className="text-lg mb-6">
                    We may share your personal information with:
                </p>
                <ul className="list-disc list-inside mb-6">
                    <li>
                    <strong className="text-black">Our service providers:</strong> We may engage third-party service providers to assist us in providing our services. These service providers may have access to your personal information to perform their functions on our behalf.
                    </li>
                    <li>
                    <strong className="text-black">Law enforcement authorities:</strong> We may disclose your information to law enforcement agencies if required by law or to protect our rights or the safety of others.
                    </li>
                </ul>

                <h2 className="text-2xl font-semibold mb-4 text-primary">Data Security</h2>
                <p className="text-lg mb-6">
                    We implement reasonable security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the internet or electronic storage is completely secure. Please be aware that there is always a risk of unauthorized access to your information, despite our best efforts.
                </p>

                <h2 className="text-2xl font-semibold mb-4 text-primary">Your Rights</h2>
                <p className="text-lg mb-6">
                    You may have certain rights regarding your personal information, including the right to:
                </p>
                <ul className="list-disc list-inside mb-6">
                    <li><strong className="text-black">Access:</strong> Request access to your personal information.</li>
                    <li><strong className="text-black">Rectification:</strong> Request correction of any inaccuracies in your personal information.</li>
                    <li><strong className="text-black">Erasure:</strong> Request deletion of your personal information.</li>
                    <li><strong className="text-black">Restriction:</strong> Request restriction of processing of your personal information.</li>
                    <li><strong className="text-black">Object:</strong> Object to the processing of your personal information.</li>
                    <li><strong className="text-black">Portability:</strong> Request transfer of your personal information to another controller.</li>
                </ul>

                <p className="text-lg mb-6">
                    To exercise your rights, please contact us using the information provided below.
                </p>

                <h2 className="text-2xl font-semibold mb-4 text-primary">Contact Information</h2>
                <p className="text-lg mb-6">
                    If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at:
                </p>
                <ul className="list-disc list-inside mb-6">
                    <li>Snipers Security</li>
                    <li>G/F Unit C, Nisus Building Sta. Cruz Manila</li>
                    <li>snipersecurity@gmail.com</li>
                    <li>(+63) 917 114 4503, (+63) 928 392 8397, (02) 7358 2445</li>
                </ul>

                <h2 className="text-2xl font-semibold mb-4 text-primary">Changes to this Privacy Policy</h2>
                <p className="text-lg mb-6">
                    We may update this Privacy Policy from time to time. Any changes will be posted on this page, and we will notify you of any material changes by email or by posting a notice on our website.
                </p>
                </div>

            </div>
          </div>
        </div>
      </div>
  </div>
  </div>
  );
};

export default PrivacyPolicy;
