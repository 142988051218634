import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../img/ss-white-logo.png'; 
import chevronDown from '../img/chevron-down.png';
import useScrollToElement from '../hooks/useScrollToElement';
import './navbar.css'; // Import the CSS file

const Navbar = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false); // Always closed initially
  const [scrolled, setScrolled] = useState(false);
  const scrollToElement = useScrollToElement();
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 40);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleMobileMenuToggle = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen); // Toggle mobile menu open/close
    if (!isMobileMenuOpen) {
      setIsDropdownOpen(false); // Close dropdown when the menu closes
    }
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false); // Ensure menu is closed when navigating
    setIsDropdownOpen(false);
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
    closeMobileMenu(); // Close mobile menu when navigating
  };

  const isServiceActive = location.pathname === '/business-services' || location.pathname === '/security-services';

  return (
    <nav className={`fixed z-[2] w-full h-auto p-4 transition-all duration-300 ease-in-out ${scrolled ? 'md:bg-black top-0' : 'md:bg-transparent'} bg-black text-white p-4 px-8 md:px-[1rem]`}>
      <div className="container mx-auto flex justify-between items-center">
        {/* Logo */}
        <div>
          <Link to="/home" onClick={() => { scrollToTop(); scrollToElement('top-section'); }}>
            <img src={logo} alt="Snipers Security Logo" className="h-[4rem]" />
          </Link>
        </div>

        {/* Desktop Menu */}
        <ul className="hidden md:flex gap-4">
          <Link to="/home">
            <li 
              className={`py-[5px] px-[15px] text-xl hover:bg-primary ${location.pathname === '/home' ? 'bg-primary text-white' : ''}`}
              onClick={() => { scrollToTop(); scrollToElement('top-section'); }}
            >Home
            </li>
          </Link>

          <li className={`relative py-[5px] px-[15px] text-xl flex items-center gap-[.5rem] cursor-pointer group ${isServiceActive ? 'bg-primary text-white' : ''}`}>
            <button className="focus:outline-none">Services</button>
            <img src={chevronDown} alt="Dropdown Chevron" className="w-[10px] h-[5px] mt-[3px] filter brightness-0 invert" />
            
            <ul className="absolute mt-3 ml-[-1rem] bg-black text-white shadow-lg rounded text-nowrap top-7 opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-300">
              <Link to="/business-services">
                <li className={`py-[5px] px-[15px] ${location.pathname === '/business-services' ? 'bg-primary text-white' : 'hover:bg-primary hover:text-white'}`}>
                  <span className="block px-4 py-2" onClick={scrollToTop}>Business Services</span>
                </li>
              </Link>
              <Link to="/security-services">
                <li className={`py-[5px] px-[15px] ${location.pathname === '/security-services' ? 'bg-primary text-white' : 'hover:bg-primary hover:text-white'}`}>
                  <span className="block px-4 py-2" onClick={scrollToTop}>Security Services</span>
                </li>
              </Link>
            </ul>
          </li>

          <Link to="/about-us">
            <li 
              className={`py-[5px] px-[15px] text-xl hover:bg-primary ${location.pathname === '/about-us' ? 'bg-primary text-white' : ''}`} 
              onClick={scrollToTop}
            >About Us
            </li>
          </Link>

          <Link to="/contact-us">
            <li 
              className={`py-[5px] px-[15px] text-xl hover:bg-primary ${location.pathname === '/contact-us' ? 'bg-primary text-white' : ''}`} 
              onClick={scrollToTop}
            >Contact Us
            </li>
          </Link>
        </ul>

        {/* Mobile Menu Button */}
        <div className="md:hidden">
          <input
            type="checkbox"
            id="checkbox1"
            className="checkbox1 visuallyHidden"
            checked={isMobileMenuOpen} // Mobile menu is controlled here
            onChange={handleMobileMenuToggle} // Toggle mobile menu on click
          />
          <label htmlFor="checkbox1">
            <div className="hamburger hamburger1">
              <span className="bar bar1"></span>
              <span className="bar bar2"></span>
              <span className="bar bar3"></span>
              <span className="bar bar4"></span>
            </div>
          </label>
        </div>
      </div>

      {/* Mobile Menu */}
      <div
        className={`md:hidden bg-black text-white overflow-hidden transition-all duration-300 ${isMobileMenuOpen ? 'dropdown-open' : 'dropdown-closed'}`}
      >
        <ul className="flex flex-col items-start p-4 gap-4 items-center">
          <li 
            className={`py-[10px] px-[25px] mt-6 hover:bg-primary text-xl tracking-wide ${location.pathname === '/home' ? 'bg-primary text-white' : ''}`}
            onClick={() => {
              scrollToTop();
              closeMobileMenu();
            }}
          >
            <Link to="/home">Home</Link>
          </li>

          <li 
            className={`py-[10px] px-[15px] text-xl tracking-wide flex items-center gap-[.5rem] ${isServiceActive ? 'bg-primary text-white' : ''}`} 
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          >
            <button className="focus:outline-none">Services</button>
            <img src={chevronDown} alt="Dropdown Chevron" className="w-[10px] h-[5px] mt-[3px] filter brightness-0 invert" />
          </li>
          <ul 
            className={`flex flex-col items-center transition-max-height duration-300 ease-in-out overflow-hidden ${isDropdownOpen ? 'max-h-screen' : 'max-h-0'}`}
          >
            <li className={`py-[10px] px-[15px] hover:bg-primary text-xl tracking-wide w-full flex justify-center ${location.pathname === '/business-services' ? 'bg-primary text-white' : 'hover:bg-primary hover:text-white'}`}>
              <Link to="/business-services" className="block" onClick={() => { scrollToTop(); closeMobileMenu(); }}>Business Services</Link>
            </li>
            <li className={`py-[10px] px-[15px] mt-2 hover:bg-primary text-xl tracking-wide w-full flex services-text justify-center ${location.pathname === '/security-services' ? 'bg-primary text-white' : 'hover:bg-primary hover:text-white'}`}>
              <Link to="/security-services" className="block" onClick={() => { scrollToTop(); closeMobileMenu(); }}>Security Services</Link>
            </li>
          </ul>

          <li 
            className={`py-[10px] px-[25px] hover:bg-primary text-xl about-us-text tracking-wide ${location.pathname === '/about-us' ? 'bg-primary text-white' : ''}`} 
            onClick={() => { scrollToTop(); closeMobileMenu(); }}
          >
            <Link to="/about-us">About Us</Link>
          </li>

          <li 
            className={`py-[10px] px-[25px] hover:bg-primary text-xl tracking-wide ${location.pathname === '/contact-us' ? 'bg-primary text-white' : ''}`} 
            onClick={() => { scrollToTop(); closeMobileMenu(); }}
          >
            <Link to="/contact-us">Contact Us </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
